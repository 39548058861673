import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAgzbQRssMectf5WZ3bAHmxtBbWVvU9cSo",
  authDomain: "starter-app-4105d.firebaseapp.com",
  databaseURL: "https://starter-app-4105d-default-rtdb.firebaseio.com",
  projectId: "starter-app-4105d",
  storageBucket: "starter-app-4105d.appspot.com",
  messagingSenderId: "177983864790",
  appId: "1:177983864790:web:f2c158bcf17e48eea4ae77",
  measurementId: "G-C70RZ6GS45"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
export { db, auth, storage, provider };

/* {
  apiKey: "AIzaSyAuxoJLKfYgqLF4fC9Vs11NcLgT-IP6i6U",
  authDomain: "ouranos-website-ee27d.firebaseapp.com",
  projectId: "ouranos-website-ee27d",
  storageBucket: "ouranos-website-ee27d.appspot.com",
  messagingSenderId: "92427441403",
  appId: "1:92427441403:web:d8781e04e74cd5ac9b8c5e",
  measurementId: "G-P40EZFE4EX"
} */