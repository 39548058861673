import React from 'react'

export default function ShippingPolicy() {
  document.title = 'Ouranos Robotics | Refund Policy'
  return (
		<>
			<div className="p-4">
      <h2><strong>Shipping Policy</strong></h2>
      <h4>Shipping Policy for Ouranos Robotics Private Limited</h4>
      <p>
        Thank you for choosing our products, designed to help you control your pump from anywhere, anytime using the KrishiVerse (formerly Neer) app. We aim to provide you with a seamless and reliable shipping experience. Please review our shipping policy below to understand the details of the shipping process:
      </p>
      <ol>
        <li>
          <strong>Shipping Locations:</strong> We currently offer shipping within the regions of India. For international orders, please contact our customer support for further assistance.
        </li>
        <li>
          <strong>Processing Time:</strong> Orders are typically processed within 1-2 business days after payment confirmation. Processing times may vary during peak seasons and holidays.
        </li>
        <li>
          <strong>Shipping Methods:</strong> We partner with reputable shipping carriers to ensure your product is delivered safely and on time. Our primary shipping methods include standard ground/cargo shipping and air shipping.
        </li>
        <li>
          <strong>Shipping Costs:</strong> Shipping costs are calculated at checkout based on your location and the chosen shipping method. We may offer free shipping promotions during special events or for qualifying orders. Please check our website or contact our customer support for current shipping rates and promotions.
        </li>
        <li>
          <strong>Delivery Time:</strong> The estimated delivery time depends on your location and the selected shipping method. Typically, national orders within India will arrive within 3-7 business days after processing. You will receive a tracking number via email to monitor the shipment's progress.
        </li>
        <li>
          <strong>Shipping Confirmation:</strong> Once your order is shipped, you will receive a shipping confirmation email containing your order details and a tracking number. You can use this tracking number to check the status of your shipment.
        </li>
        <li>
          <strong>Damaged or Lost Shipments:</strong> In the rare event that your shipment is damaged during transit or lost, please contact our customer support immediately. We will work to resolve the issue and provide a suitable solution.
        </li>
        <li>
          <strong>Change of Shipping Address:</strong> If you need to change your shipping address after placing an order, please contact our customer support as soon as possible. Address changes may be possible if the order has not yet been shipped.
        </li>
        <li>
          <strong>Returns and Refunds:</strong> For information regarding returns and refunds, please refer to our Return Policy. We are committed to ensuring your satisfaction with our products.
        </li>
        <li>
          <strong>Customer Support:</strong> If you have any questions or concerns related to shipping or your order, please feel free to contact our customer support team. We are here to assist you in any way possible. Contact us at- <a href="tel:+917747813995">+91 7747-813995</a> or <a href="mailto:sales@ouranosrobotics.com">sales@ouranosrobotics.com</a>.
        </li>
      </ol>
      <p>
        Please note that this shipping policy is subject to change, and we recommend reviewing it before making a purchase. For the most up-to-date information on shipping, please visit our website or contact our customer support.
      </p>
      <p>
        We appreciate your business and trust in our product. We look forward to providing you our products and services and supporting you in controlling your irrigation efficiently using the KrishiVerse (formerly Neer) app.
      </p>
      </div>
    </>
  )
}
