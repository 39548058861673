import React, { createRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Terms() {
	const [refs, setRefs] = useState([]);
  document.title = 'Ouranos Robotics | Terms & Conditions'

	
	useEffect(() => {
		setRefs(Array(26).fill().map((_, i) => createRef()))
	}, [])

	const handleClick = (idx) => {
    refs[idx].current.scrollIntoView({ behavior: "smooth" })
  }

  return (
		<div className="p-4">
			<h3><strong>TERMS AND CONDITIONS</strong></h3>
			<h4>1. OUR SERVICES</h4>
      <p>
        The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
      </p>

      <h4>2. INTELLECTUAL PROPERTY RIGHTS</h4>
      <p>
        <strong>Our intellectual property</strong>
        <br />
        We are the owner or the licensee of all intellectual property rights in our Services, including all products, source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').
      </p>
      <p>
        Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the Indian Subcontinent and around the world.
      </p>
      <p>
        The Content and Marks are provided in or through the Services 'AS IS' for your internal business purpose only.
      </p>
      <p>
        <strong>Your use of our Services</strong>
        <br />
        Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable license to:
      </p>
      <ul>
        <li>Access the Services</li>
        <li>Download or print a copy of any portion of the Content to which you have properly gained access solely for your internal business purpose.</li>
      </ul>
      <p>
        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
      </p>
      <p>
        If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: <a href="mailto:info@ouranosrobotics.com">info@ouranosrobotics.com</a>. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
      </p>
      <p>
        We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms, and your right to use our Services will terminate immediately.
      </p>

      <h4>3. YOUR SUBMISSIONS</h4>
      <p>
        Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
      </p>
      <p>
        <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
      </p>
      <p>
        <strong>You are responsible for what you post or upload:</strong> By sending us Submissions through any part of the Services you:
      </p>
      <ul>
        <li>Confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading.</li>
        <li>To the extent permissible by applicable law, waive any and all moral rights to any such Submission.</li>
        <li>Warrant that any such Submission is original to you or that you have the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions.</li>
        <li>Warrant and represent that your Submissions do not constitute confidential information.</li>
      </ul>
      <p>
        You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party's intellectual property rights, or (c) applicable law.
      </p>

			<h4>4. USER REPRESENTATIONS</h4>
      <p>
        By using the Services, you represent and warrant that:
      </p>
      <ol>
        <li>All registration information you submit will be true, accurate, current, and complete.</li>
        <li>You will maintain the accuracy of such information and promptly update such registration information as necessary.</li>
        <li>You have the legal capacity, and you agree to comply with these Legal Terms.</li>
        <li>You are not under the age of 13.</li>
        <li>You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services.</li>
        <li>You will not access the Services through automated or non-human means, whether through a bot, script, or otherwise.</li>
        <li>You will not use the Services for any illegal or unauthorized purpose.</li>
        <li>Your use of the Services will not violate any applicable law or regulation.</li>
      </ol>
      <p>
        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
      </p>

      <h4>5. USER REGISTRATION</h4>
      <p>
        You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
      </p>

      <h4>6. PURCHASES AND PAYMENT*</h4>
      <p>
        We accept the following forms of payment:
      </p>
      <ul>
        <li>UPI</li>
        <li>Net banking</li>
        <li>Credit/Debit Cards</li>
        <li>Wallets</li>
        <li>Cash on Delivery</li>
      </ul>
      <p>
        All these payments are collected through payment gateways.
      </p>
      <p>
        You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in INR.
      </p>
      <p>
        You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. If your order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
      </p>

      <h4>7. FREE TRIAL</h4>
      <p>
        We offer a 7-day free trial to new users who register with the Services. The account will be charged according to the user's chosen subscription at the end of the free trial.
      </p>

      <h4>8. CANCELLATION</h4>
      <p>
        They can initiate their refund within 7 days via customer service. Your cancellation will take effect at the end of the current paid term. If you are unsatisfied with our Services, please email us at <a href="mailto:sales@ouranosrobotics.com">sales@ouranosrobotics.com</a> or call us at <a href="tel:+917747813995">+917747813995</a>.
      </p>

      <h4>9. PROHIBITED ACTIVITIES</h4>
      <p>
        You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
      </p>
      <p>
        As a user of the Services, you agree not to:
      </p>
      <ul>
        <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
        <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</li>
        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
        <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
        <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
        <li>Engage in unauthorized framing of or linking to the Services.</li>
        <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Services.</li>
      </ul>
    </div>
  )
}

export default Terms