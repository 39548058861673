import React from 'react'

function RefundPolicy() {
  document.title = 'Ouranos Robotics | Refund Policy'
  return (
		<>
			<div className="p-4">
				<h3><strong>RETURN POLICY</strong></h3>
				<p>
        We have a 7-day return policy, which means you have 7 days after
        receiving your item to request a return.
      </p>
      <p>
        To be eligible for a return, your item must be in the same condition
        that you received it, unworn or unused, with tags, and in its original
        packaging. You’ll also need the receipt or proof of purchase.
      </p>
      <p>
        To start a return, you can contact us at{" "}
        <a href="tel:+917747813995">+91 7747-813995</a> or{" "}
        <a href="mailto:sales@ouranosrobotics.com">sales@ouranosrobotics.com</a>
        . Please note that returns will need to be sent to the following
        address: 307/10, In front of Basera Campus, Patel Nagar, Maharajpur,
        Jabalpur, Madhya Pradesh, Pin Code- 482004.
      </p>
      <p>
        If your return is accepted, we’ll send you a return shipping label, as
        well as instructions on how and where to send your package. Items sent
        back to us without first requesting a return will not be accepted.
      </p>
      <p>
        You can always contact us for any return question at{" "}
        <a href="tel:+917747813995">+91 7747-813995</a> or{" "}
        <a href="mailto:sales@ouranosrobotics.com">sales@ouranosrobotics.com</a>
        .
      </p>

      <h4>Damages and issues</h4>
      <p>
        Please inspect your order upon reception and contact us immediately if
        the item is defective, damaged, or if you receive the wrong item, so
        that we can evaluate the issue and make it right.
      </p>

      <h4>Exchanges</h4>
      <p>
        The fastest way to ensure you get what you want is to return the item you
        have, and once the return is accepted, make a separate purchase for the
        new item.
      </p>

      <h4>Refunds</h4>
      <p>
        We will notify you once we’ve received and inspected your return, and
        let you know if the refund was approved or not. If approved, you’ll be
        automatically refunded on your original payment method within 5 business
        days. Please remember it can take some time for your bank or credit card
        company to process and post the refund too.
      </p>
      <p>
        If more than 15 business days have passed since we’ve approved your
        return, please contact us at{" "}
        <a href="tel:+917747813995">+91 7747-813995</a> or{" "}
        <a href="mailto:sales@ouranosrobotics.com">sales@ouranosrobotics.com</a>
        .
      </p>
			</div>
		</>
  )
}

export default RefundPolicy;

